import React from "react"
import SEO from "../../components/seo"

import Layout from "../../components/layout"
const NewsPage = () => {
    return (
        <Layout>
            <SEO title="Pricing" />
            <div className='p-12 text-center'>
                <h1 className='text-4xl text-center'>Company News</h1>
                {/*<h1 className='text-xl text-center mt-4'>Jan 5th, 2021: USPTO issues patent to our CEO, Atlee Solomon.*/}
                {/*   <br />*/}
                {/*   <a className='text-blue-600' href='https://patents.google.com/patent/US20170135984A1/en'>*/}
                {/*        View Patent</a></h1>*/}


                {/*<br /><br /><br />*/}
                {/*<h1 className='text-4xl text-center'>General Biopharma News</h1>*/}
                {/*<h1 className='text-xl text-center mt-4'>Dec 18th, 2020: FDA Takes Additional Action in Fight Against*/}
                {/*    COVID-19 By Issuing Emergency Use Authorization for Second COVID-19 Vaccine.*/}
                {/*    <br />*/}
                {/*    <a className='text-blue-600' href='https://www.fda.gov/news-events/press-announcements/fda-takes-additional-action-fight-against-covid-19-issuing-emergency-use-authorization-second-covid'>*/}
                {/*        View FDA Press Release</a></h1>*/}
                {/*<h1 className='text-xl text-center mt-4'>Dec 11th, 2020: FDA Takes Key Action in Fight Against COVID-19*/}
                {/*    By Issuing Emergency Use Authorization for First COVID-19 Vaccine*/}
                {/*    <br />*/}
                {/*    <a className='text-blue-600' href='https://www.fda.gov/news-events/press-announcements/fda-takes-key-action-fight-against-covid-19-issuing-emergency-use-authorization-first-covid-19'>*/}
                {/*        View FDA Press Release</a></h1>*/}
            </div>
        </Layout>
    )
}

export default NewsPage
